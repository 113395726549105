<template>
    <div class="h100">
        <div class="main_title">Website</div>
        <div class="tabs flex">
            <div @click="changeTab(sub)" :class="['tab_label', getSubActive(sub)]" v-for="(sub, idx) in subMenuList" :key="idx">{{sub.name}}</div>
        </div>
        <div class="tab_content">
            <router-view />
        </div>
    </div>
</template>
<script>
import tabMixins from '@/mixins/tabMixins'
export default {
    name: 'Website',
    mixins: [tabMixins],
    data(){
        return {
            subMenuList: [
                {
                    name: 'Website setting',
                    path: '/manage/admin/website/website_setting'
                },
                {
                    name: 'Solutions',
                    path: '/manage/admin/website/solutions'
                },
                {
                    name: 'Contact us',
                    path: '/manage/admin/website/contactus'
                },
                {
                    name: 'About us',
                    path: '/manage/admin/website/aboutus'
                },
            ]
        }
    },
}
</script>
<style lang="stylus" scoped>
</style>